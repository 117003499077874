body {
  margin: 0;
  font-family: 'Montserrat', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F8F8F8 !important;
  color: #141414 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header-propio{
  background-color: #F8F8F8 !important;
  color: #141414 !important;
  box-shadow: none !important;
  height: 97px;
}

.header-propio li > a{
  color: #141414 !important;
}

.header-propio ul {
  margin-right: 5%;
  margin-top: 15px;
}

/* textos y titulos */
.titulo-sn{
  color: #1F2D41;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  letter-spacing: 2px;
}

.titulo-1{
  color: #1F2D41;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  letter-spacing: 2px;
}
.titulo-1::after{
  content: url(./Images/titulos.png);
  background-size: 100%;
  background-repeat: no-repeat;
}

.titulo-2{
  color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  letter-spacing: 2px;
}
.titulo-2::after{
  content: url(./Images/titulos.png);
  background-size: 100%;
  background-repeat: no-repeat;
}

.contador{
  font-weight: bolder;
  margin: 0;
}

.texto-pequeño{
  font-size: 12px;
  margin: 0;
}

.titulo-error-1{
  font-size: 145px;
  margin: 0;
  color: #0084C4;
  line-height: 145px;
  font-weight: bolder;
}
.titulo-error-2{
  font-size: 70px;
  letter-spacing: 5px;
  margin: 0;
  color: #1F2D41;
  line-height: 70px;
  font-weight: bold;
}
.texto-error-1{
  font-size: 20px;
  margin: 0px;
}
/* textos y titulos */

/* cards */
.servicios-card{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
}
.servicios-card img{
  max-width: 100%;
  height: auto;
  transition: all linear 0.3s;
}
.servicios-card:hover img{
  transform: scale(1.2);
  transition: all linear 0.3s;
}
.servicios-card > div{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  text-align: center;
  padding: 10px;
  color: #ffffff;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
}

.proyectos-crad{
  position: relative;
  max-width: 100%;
  overflow: hidden;
}
.proyectos-crad img{
  width: 100%;
  height: auto;
  transition: all linear 0.3s;
}
.proyectos-crad:hover img{
  transform: scale(1.2);
  transition: all linear 0.3s;
}
.proyectos-crad > div{
  position: relative;
  z-index: 9999;
  margin-top: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
  color: #ffffff;
  width: 100%;
  height: 60px;
}
.proyectos-crad p{
  margin: 0;
}
/* cards */


/* inputs */
.input-field > input{
  border: solid 3px #141414 !important;
  padding-left: 15px !important;
  width: calc(100% - 15px) !important;
  transition: all linear 0.2s;
}
.input-field > input:focus{
  border: solid 3px #046DA0 !important;
  transition: all linear 0.2s;
}
.input-field > label{
  padding-left: 15px;
}

.input-field > textarea{
  border: solid 3px #141414 !important;
  padding-left: 15px !important;
  width: 100% !important;
  height: 150px !important;
  transition: all linear 0.2s;
}
.input-field > textarea:focus{
  border: solid 3px #046DA0 !important;
  transition: all linear 0.2s;
}
/* inputs */

/* botones */
.boton-1{
  background-color: #BE4F00;
  border: solid 3px #BE4F00;
  color: #ffffff;
  width: 150px;
  font-size: 15px;
  font-weight: bolder;
  padding: 10px 20px;
  transition: all linear 0.3s;
}
.boton-1:hover{
  background-color: #ffffff;
  color: #BE4F00;
  transition: all linear 0.3s;
}

.boton-2{
  background-color: #BE4F00;
  border: solid 3px #BE4F00;
  color: #ffffff;
  font-size: 15px;
  font-weight: bolder;
  padding: 10px 20px;
  transition: all linear 0.3s;
}
.boton-2:hover{
  background-color: #ffffff;
  color: #BE4F00;
  transition: all linear 0.3s;
}
/* botones */


/* contenedores */
.imagne-principal{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
}
.imagne-principal img{
  max-width: 100%;
  height: auto;
}
.imagne-principal > div{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  text-align: center;
  padding: 10px;
  color: #ffffff;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
}

.contenedor-1{
  background-color: #1F2D41;
  color: #ffffff !important;
  width: 60%;
  margin: 60px auto;
  padding: 30px 0;
}

.galeria{
  display: flex;
  flex-wrap: wrap;
}
.galeria-2{
  display: flex;
  flex-wrap: wrap;
  width: 100% !important;
}
.galeria-2 img {
  width: calc(100% / 3) !important;
}
/* contenedores */


/* linea de progreso */
.progress{
  height: 9px !important;
  border-radius: 15px;
  background-color: transparent !important;
}

.progress .determinate{
  border-radius: 15px;
}
/* linea de progreso */

/* linea de tiempo */
.comment{
  margin-left: 160px;
  width: calc(100% - 160px);
  border-left: solid 2px #D1E9FF;
}
.comment p{
  padding: 10px 25px;
  position: relative;
  font-weight: bolder;
  color: #77808B;
  cursor: default;
}
.comment p::before{
  content: '';
  position: absolute;
  width: 17px;
  height: 17px;
  border: solid 2px #D1E9FF;
  border-radius: 50%;
  background-color: #3A86FF;
  top: 10px;
  left: -10px;
}
.comment p::after{
  content: attr(data-text);
  position: absolute;
  top: 4px;
  left: -72px;
  font-size: 18px;
  color: #77808B;
  transition: all linear 0.3s;
}
.comment p:hover::after{
  font-size: 20px;
  transition: all linear 0.3s;
}
.comment .margin-timeline::after{
  content: attr(data-text);
  position: absolute;
  top: 4px;
  left: -135px;
  font-size: 18px;
  color: #77808B;
}

/* linea de tiempo */

.centrar-flex{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.invisible{
  display: none;
}

@media only screen and (min-width:200px) and (max-width:550px){
  .alineacion-footer{
    color: rgb(31, 45, 65);
    font-size: 15px;
  }

  .center-movil{
    text-align: center !important;
  }

  footer{
    text-align: center;
  }

  .padding-1{
    padding-top: 50px !important;
  }

  .visible_c{
    display:inline;
  }

  /* textos y titulos */
  body{
    font-size: 14px;
  }

  .titulo-sn{
    font-size: 21px;
  }
  .titulo-1{
    font-size: 21px;
  }
  .titulo-2{
    font-size: 15px;
  }
  .titulo-3{
    font-size: 30px;
    line-height: 30px;
  }
  .titulo-4{
    font-size: 40px;
    line-height: 40px;
    font-weight: bolder;
  }

  .titulo-error-1{
    font-size: 125px;
    line-height: 125px;
  }
  .titulo-error-2{
    font-size: 40px;
    line-height: 40px;
  }
  
  .contador{
    font-size: 30px;
    line-height: 30px;
  }
  .contenedor-1{
    width: 80%;
    margin: 60px auto;
  }
  .galeria > div{
    width: calc(100% / 2) !important;
  }

  .servicios-card {
    padding: 10px;
  }
  .servicios-card > div {
    width: calc(100% - 20px) !important;
    height: calc(100% - 20px) !important;
  }
  .proyectos-crad{
    padding: 10px;
  }
  /* textos y titulos */
}

@media only screen and (min-width:551px) and (max-width:992px){
  .alineacion-footer{
    color: rgb(31, 45, 65);
    font-size: 15px;
  }

  .center-movil{
    text-align: center !important;
  }

  .padding-1{
    padding: 60px 30% 0 30% !important;
  }

  .visible_m{
    display:inline;
  }

  /* textos y titulos */
  body{
    font-size: 14px;
  }

  .titulo-sn{
    font-size: 21px;
  }
  .titulo-1{
    font-size: 21px;
  }
  .titulo-2{
    font-size: 15px;
  }
  .titulo-3{
    font-size: 35px;
    line-height: 35px;
  }
  .titulo-4{
    font-size: 50px;
    line-height: 50px;
    font-weight: bolder;
  }

  .contador{
    font-size: 40px;
    line-height: 40px;
  }
  .contenedor-1{
    width: 75%;
    padding: 30px 20px;
  }
  .galeria > div{
    width: calc(100% / 2) !important;
  }
  /* textos y titulos */
}

@media only screen and (min-width:993px) and (max-width:1664px){
  .alineacion-footer{
    color: rgb(31, 45, 65);
    font-size: 15px;
    text-align: left;
    width: 80px;
  }
  .margen-1{
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .padding-2{
    padding-left: 62px !important;
    padding-right: 0px !important;
  }

  .visible_mg{
    display:inline;
  }

  /* textos y titulos */
  body{
    font-size: 14px;
  }

  .titulo-sn{
    font-size: 21px;
  }
  .titulo-1{
    font-size: 21px;
  }
  .titulo-2{
    font-size: 15px;
  }
  .titulo-3{
    font-size: 35px;
    line-height: 35px;
  }
  .titulo-4{
    font-size: 50px;
    line-height: 50px;
    font-weight: bolder;
  }

  .contador{
    font-size: 40px;
    line-height: 40px;
  }
  .galeria > div{
    width: calc(100% / 4) !important;
  }
  /* textos y titulos */
}

@media only screen and (min-width:1665px){
  .alineacion-footer{
    color: rgb(31, 45, 65);
    font-size: 15px;
    text-align: left;
    width: 80px;
  }

  .margen-1{
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  
  .padding-1{
    padding-left: 60px !important;
  }
  .padding-2{
    padding-left: 62px !important;
    padding-right: 0px !important;
  }

  .visible_g{
    display:inline;
  }

  /* textos y titulos */
  body{
    font-size: 20px;
  }

  .titulo-sn{
    font-size: 25px;
  }
  .titulo-1{
    font-size: 25px;
  }
  .titulo-2{
    font-size: 20px;
  }
  .titulo-3{
    font-size: 45px;
    line-height: 45px;
  }
  .titulo-4{
    font-size: 60px;
    line-height: 60px;
    font-weight: bolder;
  }

  .contador{
    font-size: 44px;
    line-height: 44px;
  }
  .galeria > div{
    width: calc(100% / 4) !important;
  }
  /* textos y titulos */
}
